import React from 'react';
import roadmap from '../images/roadmap.png';
import './Roadmap.css';

const Roadmap = () => {
  return (
    <section className="roadmap-container">
      <h1>ROADMAP</h1>
      <img src={roadmap} alt="roadmap" />
    </section>
  );
};

export default Roadmap;
