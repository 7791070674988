import React, { useState } from 'react';
import Modal from 'react-modal';
import './App.css';
import Header from './header/Header';
import Roadmap from './Roadmap/Roadmap';
import Community from './community/Community';
import About from './about/About';
import Footer from './footer/Footer';

import Music from './music/Music';

function App() {
  const [modalIsOpen, setModalIsOpen] = useState(true);
  const [musicIsPlaying, setMusicIsPlaying] = useState(false);

  // Función para cerrar el modal
  const closeModal = () => {
    setModalIsOpen(false);
  };

  const handleModalAccept = () => {
    setModalIsOpen(false);
    // Aquí podrías agregar lógica adicional antes de cambiar isPlaying a true si es necesario
    setMusicIsPlaying(true);
  };

  return (
    <div className="App">
      <Header />
      <Community />
      <Roadmap />
      <About />
      <Footer />
      <Music isPlaying={musicIsPlaying} />

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Are you a Chad?"
        className="content-modal"
      >
        <h2>Are you a Chad?</h2>
        <button className='btn-23' onClick={handleModalAccept}>
          <span class="text">YES</span>
          <span aria-hidden="" class="marquee">YES</span>
        </button>
      </Modal>
    </div>
  );
}

export default App;
