import React, { useState, useEffect } from 'react';
import music from "./chadsong.mp3";
import { IoMdPlay, IoMdPause } from 'react-icons/io';

const Music = ({ isPlaying }) => {
    const [audio] = useState(new Audio(music));
    const [volume, setVolume] = useState(0.5);
    const [playing, setPlaying] = useState(!audio.paused);

    useEffect(() => {
        audio.volume = volume;

        if (isPlaying) {
            audio.play().catch(error => {
                console.error("Error al reproducir audio:", error);
            });
        } else {
            audio.pause();
        }

        setPlaying(!audio.paused); // Actualizar el estado inmediatamente

        return () => {
            audio.pause();
            audio.currentTime = 0;
        };
    }, [isPlaying, volume, audio]);

    const handlePlayPause = () => {
        playing ? audio.pause() : audio.play();
        setPlaying(!audio.paused); // Actualizar el estado inmediatamente
    }
    
    const handleVolumeChange = (e) => {
        const newVolume = parseFloat(e.target.value);
        setVolume(newVolume);
    };


    return (
        <div style={{ position: 'fixed', bottom: 0, right: 0, padding: '10px' }}>
            <button onClick={handlePlayPause}>
                {audio.paused ? <IoMdPlay /> : <IoMdPause />}
            </button>
            <input
                type="range"
                min="0"
                max="1"
                step="0.01"
                value={volume}
                onChange={handleVolumeChange}
            />
        </div>
    );
};

export default Music;
