import React from 'react';
import communityImage from '../images/community.png';
import chad from '../images/Chad.png';
import "./Community.css"

const Community = () => {
  return (
    <section className="community-container">
      <h2 className='title-community'>COMMUNITY</h2>
      <div className="community-images">
        <img className="horizontal-flip left-image" src={chad} alt="Chad" />
        <img className="center-image" src={communityImage} alt="Join" />
        <img className="horizontal-flip" src={chad} alt="Chad" />
      </div>
    </section>
  );
};

export default Community;
