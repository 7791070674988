// About.js
import React, { useEffect } from 'react';
import aboutImage1 from '../images/image.png';
import aboutImage2 from '../images/image2.png';
import aboutImage3 from '../images/image3.jpg';
import { Element } from 'react-scroll';
import './About.css';

const About = () => {
    useEffect(() => {
        const handleScroll = () => {
            const elements = document.querySelectorAll('.about-block');
            elements.forEach((element) => {
                const rect = element.getBoundingClientRect();
                const viewHeight = Math.max(document.documentElement.clientHeight, window.innerHeight);
                if (rect.top <= viewHeight * 0.75) {
                    element.classList.add('active');
                }
            });
        };

        window.addEventListener('scroll', handleScroll);
        handleScroll(); // Inicializar la visibilidad de los elementos al cargar la página

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    return (
        <Element name="about" className="about-container">
            <h2>About Us</h2>

            <div className="about-block">
                <div className="about-content">
                    <h3>Stay safe in this adventure</h3>
                    <p>
                        Liquidity burned, 0 tax and fair launch on Raydium!
                    </p>
                </div>
                <div className="about-image from-left">
                    <img src={aboutImage1} alt="About Image 1" />
                </div>
            </div>

            <div className="about-block">
                <div className="about-image from-right">
                    <img src={aboutImage2} alt="About Image 2" />
                </div>
                <div className="about-content2">
                    <h3>Calls</h3>
                    <p>
                        Chad callers to support us on the travel.
                    </p>
                </div>
            </div>

            <div className="about-block">
                <div className="about-content">
                    <h3>Based team</h3>
                    <p>
                        Chad team won't let you down.
                    </p>
                </div>
                <div className="about-image from-left">
                    <img src={aboutImage3} alt="About Image 3" />
                </div>
            </div>
        </Element>
    );
};

export default About;
